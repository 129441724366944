import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2dc1b072"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: ""
};
const _hoisted_2 = {
  class: "breadcrumb-wrap"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_empty = _resolveComponent("van-empty");
  const _component_Footer = _resolveComponent("Footer");
  const _component_van_back_top = _resolveComponent("van-back-top");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
    title: $data.lang.u1,
    onClickLeft: $options.back,
    onClickRight: $options.onClickLeft
  }, {
    left: _withCtx(() => [_createVNode(_component_van_icon, {
      name: "arrow-left"
    })]),
    right: _withCtx(() => [_createVNode(_component_van_icon, {
      name: "chat-o",
      size: "18"
    })]),
    _: 1
  }, 8, ["title", "onClickLeft", "onClickRight"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("h2", _hoisted_3, _toDisplayString($data.goods.name), 1), _createElementVNode("div", {
    innerHTML: $data.goods.describe,
    class: "content"
  }, null, 8, _hoisted_4), $data.goods.name == undefined ? (_openBlock(), _createBlock(_component_van_empty, {
    key: 0,
    description: $data.lang['t31']
  }, null, 8, ["description"])) : _createCommentVNode("", true)]), _createVNode(_component_Footer, {
    lang_list: $data.lang_list,
    lang: $data.lang
  }, null, 8, ["lang_list", "lang"]), _createVNode(_component_van_back_top, {
    bottom: "10vh"
  })]);
}